@use '~@angular/material' as mat;
@import "theme.scss";
@import "layout.scss";
@import "character.scss";
@import "~swiper/swiper-bundle.css";

html, body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  color: mat.get-color-from-palette($d10-primary, 50);
}
a {
  text-decoration: none;
}
b {
  font-weight: 500;
}
input,
textarea,
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  caret-color: mat.get-color-from-palette($d10-primary, 100) !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
//Utility
.top-spacer {
  margin-top: $default-spacing !important;
}
.right-spacer {
  margin-right: $default-spacing !important;
}
.bottom-spacer {
  margin-bottom: $default-spacing !important;
}
.left-spacer {
  margin-left: $default-spacing !important;
}
.float-right {
  float: right !important;
}
.float-left {
  float: left !important;
}
.nowrap {
  white-space: nowrap !important;
}
.max-width {
  width: 100% !important;
}
.min-width {
  width: 1% !important;
}
.align-left {
  text-align: left !important;
}
.align-right {
  text-align: right !important;
}
.align-center {
  text-align: center !important;
}
.inline {
  display: inline-block;
}
hr {
  border: 1px solid mat.get-color-from-palette($d10-primary, 700);
}
hr.light {
  border: 1px solid mat.get-color-from-palette($d10-primary, 400);
}
//Design
mat-card.mat-card {
  min-width: 20px;
  min-height: 20px;
  padding: $double-spacing;
  overflow: auto;
  @include border-frame(700);
  background: mat.get-color-from-palette($d10-primary, 500) !important;
  color: mat.get-color-from-palette($d10-primary, 50) !important;
  box-shadow: none !important;
  .title {
    padding: $default-spacing $double-spacing;
    @include border-frame(500);
    background: mat.get-color-from-palette($d10-primary, 700) !important;
  }
  .mat-card-content {
    padding: 0 $half-spacing;
  }
}
.mat-form-field {
  color: mat.get-color-from-palette($d10-primary, 50) !important;
  .mat-form-field-label {
    color: mat.get-color-from-palette($d10-primary, 300) !important;
  }
  .mat-form-field-underline {
    background-color: mat.get-color-from-palette($d10-primary, 300) !important;
  }
  .mat-form-field-outline {
    color: mat.get-color-from-palette($d10-primary, 400) !important;
  }
  .mat-form-field-ripple {
    background-color: mat.get-color-from-palette($d10-primary, 300) !important;
  }
}
.mat-form-field.mat-focused {
  .mat-form-field-label {
    color: mat.get-color-from-palette($d10-accent) !important;
  }
  .mat-form-field-underline {
    background-color: mat.get-color-from-palette($d10-primary, 200) !important;
  }
  .mat-form-field-outline {
    color: mat.get-color-from-palette($d10-primary, 200) !important;
  }
  .mat-form-field-ripple {
    background-color: mat.get-color-from-palette($d10-primary, 200) !important;
  }
}
.mat-form-field.no-hint {
  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }
  .mat-form-field-underline {
    bottom: 0;
  }
  .mat-form-field-flex {
    padding-top: 0;
  }
}
.mat-form-field.no-label {
  .mat-form-field-infix {
    border-top: 0;
  }
}
.mat-autocomplete-panel {
  background: mat.get-color-from-palette($d10-primary, 500);
  .mat-option {
    height: auto;
    font-size: 14px;
    font-weight: normal;
    color: mat.get-color-from-palette($d10-primary, 50);
    padding: 8px 16px;
    line-height: 20px;
    .mat-option-text {
      .title {
        max-height: $double-spacing;
        line-height: $double-spacing;
      }
      .subtitle {
        max-height: $double-spacing - $half-spacing;
        line-height: $double-spacing - $half-spacing;
      }
    }
  }
  .mat-option.mat-selected {
    color: mat.get-color-from-palette($d10-primary, 50) !important;
    background: mat.get-color-from-palette($d10-primary, 400) !important;
  }
}
.mat-autocomplete-panel-above .mat-autocomplete-panel {
  border-radius: $default-radius!important;
}

.mat-table, .mat-paginator {
  background: transparent;
  .mat-header-cell {
    color: mat.get-color-from-palette($d10-primary, 50);
    font-size: 12px;
  }
  .mat-cell, .mat-footer-cell {
    color: mat.get-color-from-palette($d10-primary, 100);
    padding: $half-spacing !important;
  }
  fa-icon {
    color: mat.get-color-from-palette($d10-primary, 200);
  }
  th.mat-header-cell:first-of-type,
  td.mat-cell:first-of-type,
  td.mat-footer-cell:first-of-type {
    padding-left: $half-spacing;
  }
  .mat-paginator-container {
    color: mat.get-color-from-palette($d10-primary, 50);
    .mat-button-disabled {
      .mat-paginator-icon {
        color: mat.get-color-from-palette($d10-primary, 200);
      }
    }
  }
}

.mat-select-panel {
  background: mat.get-color-from-palette($d10-primary);
  .mat-option.mat-selected:not(.mat-option-disabled) {
    font-weight: 500;
    color: mat.get-color-from-palette($d10-primary, 50);
  }
  .mat-option.mat-selected:not(.mat-option-multiple) {
    background: mat.get-color-from-palette($d10-primary, 400);
  }
}

.mat-dialog-container {
  background: mat.get-color-from-palette($d10-primary, 500);
  color: mat.get-color-from-palette($d10-primary, 50);
}
.mat-dialog-content {
  overflow: visible!important;
}

.flex {
  display: flex;
  justify-content: center;
  flex-direction: row;
}
.flex.flex-col {
  flex-direction: column;
}
.flex-even {
  justify-content: space-between!important;
}
.flex-center {
  justify-content: center!important;
}

.flex-group {
  display: flex;
  flex-flow: wrap row;
  align-items: baseline;
  .flex-field {
    flex: 1 1 calc(25% - #{$default-spacing * 4}) !important;
    margin: $half-spacing;
    min-width: $default-size;
    .mat-icon-button {
      @include size($default-size - $double-spacing);
      line-height: $default-size - $double-spacing;
    }
  }
  .flex-field.flat {
    margin: 0;
  }
  .flex-field.level {
    display: flex;
    flex-direction: column;
    align-items: center;
    .title,
    .subtitle {
      flex: 1 1 100%;
      text-align: center;
    }
    .field-value {
      flex: 1 1 100%;
      min-width: $default-margin;
      max-height: $default-margin - $half-spacing;
      padding: $default-spacing;
      font-weight: 500;
      @include border-frame(500);
      background: mat.get-color-from-palette($d10-primary, 700);
    }
    .field-value-group {
      flex: 1 1 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      .field-value {
        flex: 1 1 100%;
        min-width: $default-margin;
      }
    }
    .field-value.number {
      text-align: center;
    }
  }
}
.flex-bottom {
  align-items: flex-end;
}
.flex-field.span-third {
  flex-basis: calc(33% - #{$default-spacing * 3})!important;
  min-width: $default-size + $double-spacing;
}
.flex-field.span-half {
  flex-basis: calc(50% - #{$default-spacing * 3})!important;
  min-width: $default-size * 2;
}
.flex-field.span-two-thirds {
  flex-basis: calc(66% - #{$default-spacing * 3})!important;
  min-width: $default-size * 2 + $double-spacing * 2;
}
.flex-field.span-three-quarters {
  flex-basis: calc(75% - #{$default-spacing * 2})!important;
  min-width: $default-size * 3;
}
.flex-field.span-full {
  flex-basis: calc(100% - #{$default-spacing})!important;
}
// FLAT
.flex-field.flat {
  flex-basis: calc(25% - #{$default-spacing * 4 * 3}) !important;
}
.flex-field.flat.span-third {
  flex-basis: calc(33% - #{$default-spacing * 3 * 3})!important;
}
.flex-field.flat.span-half {
  flex-basis: calc(50% - #{$default-spacing * 3 * 3})!important;
}
.flex-field.flat.span-two-thirds {
  flex-basis: calc(66% - #{$default-spacing * 3 * 3})!important;
}
.flex-field.flat.span-three-quarters {
  flex-basis: calc(75% - #{$default-spacing * 2 * 3})!important;
}
.flex-field.flat.span-full {
  flex-basis: calc(100% - #{$default-spacing * 3})!important;
}
.flex-line {
  display: flex;
  justify-content: space-between;
}

input.mat-input-element {
  font-weight: 300;
}
.title {
  font-weight: 500;
}
.subtitle {
  font-size: 12px;
  color: mat.get-color-from-palette($d10-primary, 200) !important;
}
.title-header {
  border-bottom: 2px solid mat.get-color-from-palette($d10-primary, 700);
}
.page-title {
  font-size: 24px;
  color: mat.get-color-from-palette($d10-accent);
  padding: 16px 0;
}
.page-subtitle {
  font-size: 18px;
  color: mat.get-color-from-palette($d10-primary, 200);
  padding: 8px 0;
}
.page-header {
  font-size: 16px;
  color: mat.get-color-from-palette($d10-primary, 300);
  padding: 4px 0;
}

@each $name, $color in $colors {
  .button-#{$name} {
    color: mat.get-color-from-palette(nth($color, 1)) !important;
    border-color: mat.get-color-from-palette(nth($color, 1), default, 0.24) !important;
    .mat-button-focus-overlay {
      background: mat.get-color-from-palette(nth($color, 1)) !important;
    }
    fa-icon {
      color: mat.get-color-from-palette(nth($color, 1)) !important;
    }
  }
  .button-#{$name}.mat-fab,
  .button-#{$name}.mat-mini-fab {
    background: mat.get-color-from-palette(nth($color, 1), 500) !important;
    fa-icon {
      color: mat.get-color-from-palette(nth($color, 1), nth($color, 2)) !important;
    }
  }
  .text-#{$name} {
    color: mat.get-color-from-palette(nth($color, 1)) !important;
    font-weight: 500;
  }
  .text-#{$name}-light {
    color: mat.get-color-from-palette(nth($color, 1), nth($color, 2)) !important;
    font-weight: 500;
  }
  .text-#{$name}-dark {
    color: mat.get-color-from-palette(nth($color, 1), nth($color, 3)) !important;
    font-weight: 500;
  }
  .mat-form-field.mat-focused.input-#{$name} {
    .mat-form-field-label {
      color: mat.get-color-from-palette(nth($color, 1)) !important;
    }
  }
  .mat-form-field.mat-focused.input-#{$name}-light {
    .mat-form-field-label {
      color: mat.get-color-from-palette(nth($color, 1), nth($color, 2)) !important;
    }
  }
  .mat-form-field.mat-focused.input-#{$name}-dark {
    .mat-form-field-label {
      color: mat.get-color-from-palette(nth($color, 1), nth($color, 3)) !important;
    }
  }
  .mat-checkbox-indeterminate.checkbox-#{$name} .mat-checkbox-background,
  .mat-checkbox-checked.checkbox-#{$name} .mat-checkbox-background {
    background-color: mat.get-color-from-palette(nth($color, 1)) !important;
  }
  .mat-checkbox-checked:not(.mat-checkbox-disabled).checkbox-#{$name} .mat-ripple-element,
  .mat-checkbox:active:not(.mat-checkbox-disabled).checkbox-#{$name} .mat-ripple-element {
    background: mat.get-color-from-palette(nth($color, 1)) !important;
  }
  .mat-checkbox-indeterminate.checkbox-#{$name}-light .mat-checkbox-background,
  .mat-checkbox-checked.checkbox-#{$name}-light .mat-checkbox-background {
    background-color: mat.get-color-from-palette(nth($color, 1), nth($color, 2)) !important;
  }
  .mat-checkbox-checked:not(.mat-checkbox-disabled).checkbox-#{$name}-light .mat-ripple-element,
  .mat-checkbox:active:not(.mat-checkbox-disabled).checkbox-#{$name}-light .mat-ripple-element {
    background: mat.get-color-from-palette(nth($color, 1), nth($color, 2)) !important;
  }
  .mat-checkbox-indeterminate.checkbox-#{$name}-dark .mat-checkbox-background,
  .mat-checkbox-checked.checkbox-#{$name}-dark .mat-checkbox-background {
    background-color: mat.get-color-from-palette(nth($color, 1), nth($color, 3)) !important;
  }
  .mat-checkbox-checked:not(.mat-checkbox-disabled).checkbox-#{$name}-dark .mat-ripple-element,
  .mat-checkbox:active:not(.mat-checkbox-disabled).checkbox-#{$name}-dark .mat-ripple-element {
    background: mat.get-color-from-palette(nth($color, 1), nth($color, 3)) !important;
  }
}

.mat-expansion-panel.component-body {
  color: mat.get-color-from-palette($d10-primary, 50) !important;
  box-shadow: none !important;
  @include border-frame(700);
  margin: -4px;
  background: mat.get-color-from-palette($d10-primary, 500) !important;
  .component-title {
    padding: $default-spacing 0;
    font-weight: 500;
    @include border-frame(500);
    background: mat.get-color-from-palette($d10-primary, 700) !important;
  }
  .mat-expansion-panel-header {
    font-weight: 500;
    min-height: $default-margin;
    height: auto!important;
    padding: $default-spacing;
    background: transparent !important;
    color: mat.get-color-from-palette($d10-primary, 100) !important;
    .mat-expansion-panel-header-description {
      justify-content: flex-end;
      text-align: right;
      font-size: 12px;
    }
  }
  .title {
    padding: $default-spacing $double-spacing;
    @include border-frame(500);
    background: mat.get-color-from-palette($d10-primary, 700) !important;
    .mat-expansion-panel-header-description {

    }
  }
  .mat-expansion-panel-header-description,
  .mat-expansion-indicator::after {
    color: mat.get-color-from-palette($d10-primary, 500);
  }
  .mat-expansion-panel-body {
    padding: 0 $double-spacing $double-spacing;
  }
}
.mat-expansion-panel.sub-panel {
  background: mat.get-color-from-palette($d10-primary, 500);
  box-shadow: none!important;
  padding-bottom: 4px;
  border-bottom: 1px solid mat.get-color-from-palette($d10-primary, 700);
  border-radius: 0;
  margin-bottom: 4px;
  .mat-expansion-panel-header {
    padding: 4px 4px 4px 0;
  }
  .mat-expansion-panel-header-description,
  .mat-expansion-indicator::after {
    color: mat.get-color-from-palette($d10-primary, 700);
  }
  .mat-expansion-panel-body {
    padding: 0;
  }
  &.sub-header {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
    &.active > .mat-expansion-panel-header > .mat-expansion-indicator::after {
      color: mat.get-color-from-palette($cc-active);
      padding: 2px;
    }
    &.passive > .mat-expansion-panel-header > .mat-expansion-indicator::after {
      color: mat.get-color-from-palette($cc-passive);
      padding: 2px;
    }
  }
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(234px,1fr));
  grid-auto-rows: auto;
  grid-gap: $default-spacing;
}
.card-grid.flat {
  margin: 0;
}
.card-grid.condensed .mat-card {
  margin-bottom: 0;
}
.card-grid.card-grid-l {
  grid-template-columns: repeat(auto-fill,minmax(312px,1fr));
}
.card-grid.card-grid-m {
  grid-template-columns: repeat(auto-fill,minmax(200px,1fr));
}
.card-grid.card-grid-s {
  grid-template-columns: repeat(auto-fill,minmax(112px,1fr));
}
.grid-card {
  margin: 0;
}
.grid-list .grid-card {
  margin-bottom: 8px;
}
@for $i from 1 through 10 {
  .grid-card.grid-span-#{$i} {
    grid-column: span #{$i};
  }
}
@for $i from 1 through 10 {
  .grid-card.grid-rowspan-#{$i} {
    grid-row: span #{$i};
  }
}
mat-stepper {
  margin: -4px;
  @include border-frame(700);
  background: mat.get-color-from-palette($d10-primary, 500) !important;
  .mat-step {
    mat-step-header.mat-step-header {
      padding: 8px;
      background-color: #3f3f44;
      @include border-frame(500);
      .mat-step-icon {
        border-radius: 4px;
        color: mat.get-color-from-palette($d10-accent);
        background: mat.get-color-from-palette($d10-primary, 500);
      }
    }
    .mat-step-header.cdk-keyboard-focused,
    .mat-step-header.cdk-program-focused,
    .mat-step-header:hover:not([aria-disabled]),
    .mat-step-header:hover[aria-disabled=false] {
      background-color: #3f3f44;
      @include border-frame(500);
    }
    .mat-vertical-content-container {
      margin-left: 24px;
      .mat-vertical-stepper-content.closed {
        height: 0;
        visibility: hidden;
      }
      .mat-vertical-content {
        padding: 4px 24px 12px 24px;
      }
    }
    .mat-stepper-vertical-line::before {
      top: 0;
      bottom: 0;
      border-left-color: mat.get-color-from-palette($d10-primary, 700);
    }
  }
}
mat-stepper.closed {
  .mat-vertical-stepper-content {
    height: 0;
    visibility: hidden;
  }
}
.text-light {
  color: mat.get-color-from-palette($d10-primary, 100);
}

// Swiper
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: -8px;
}
.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: -8px;
}
.swiper-button-prev,
.swiper-button-next {
  top: 26px;
  color: mat.get-color-from-palette($d10-accent);
}
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after,
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after{
  font-size: 14px;
  font-weight: bold;
}
.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  display: none;
}

.mat-expansion-panel.subcomponent-body {
  color: mat.get-color-from-palette($d10-primary, 50) !important;
  box-shadow: none !important;
  background: mat.get-color-from-palette($d10-primary, 500) !important;
  .subcomponent-header {
    font-weight: 500;
    min-height: $default-margin;
    height: auto!important;
    padding: $default-spacing $double-spacing;
    @include border-frame(500);
    background: mat.get-color-from-palette($d10-primary, 700) !important;
    color: mat.get-color-from-palette($d10-primary, 100) !important;
    .mat-expansion-panel-header-description {
      justify-content: flex-end;
      text-align: right;
      font-size: 12px;
    }
  }
  .mat-expansion-panel-header-description,
  .mat-expansion-indicator::after {
    color: mat.get-color-from-palette($d10-primary, 500);
  }
  .mat-expansion-panel-body {
    padding: 0 $double-spacing $default-spacing;
  }
  .mat-chip-list-wrapper {
    margin-top: 4px;
  }
  .mat-chip.mat-standard-chip {
    background-color: mat.get-color-from-palette($d10-primary, 700);
    color: mat.get-color-from-palette($d10-primary, 400);
    font-size: 12px;
    border-radius: $default-radius;
    padding: $default-spacing;
    min-height: $default-margin;
    margin: 2px;
  }
  .mat-chip.mat-standard-chip::after {
    background: mat.get-color-from-palette($d10-primary, 500);
  }
}
button.mat-button-base.small {
  min-width: 34px;
  line-height: 25px;
  padding: 0 $default-spacing;
}
.mat-form-field.medium {
  max-width: 128px;
}
.mat-form-field.small {
  max-width: 64px;
}
.mat-form-field.tiny {
  max-width: 32px;
}
.remove-icon:hover,
.remove-icon:focus {
  color: mat.get-color-from-palette($d10-warn) !important;
  cursor: pointer;
}
.add-icon:hover,
.add-icon:focus {
  color: mat.get-color-from-palette($d10-accent) !important;
  cursor: pointer;
}
.top-border-dark {
  border-top: 1px solid mat.get-color-from-palette($d10-primary, 700);
}
.underlined {
  border-bottom: 1px solid mat.get-color-from-palette($d10-primary, 400);
}
.cdk-overlay-pane {
  width: auto!important;
}
.mat-select-arrow {
  color: mat.get-color-from-palette($d10-primary, 700);
}
.mat-snack-bar-container {
  color: mat.get-color-from-palette($d10-primary, 300);
  background: mat.get-color-from-palette($d10-primary, 900);
}
.d10-image {
  @include border-frame(500);
  width: 100%;
}
.mat-tooltip {
  color: mat.get-color-from-palette($d10-primary, 100)!important;
  background: mat.get-color-from-palette($d10-primary, 800, 0.9);
  font-size: 11px;
}

.component {
  background: mat.get-color-from-palette($d10-primary, 500);
  @include border-frame(700);
  .component-header {
    background: mat.get-color-from-palette($d10-primary, 700);
    @include border-frame(500);
    padding: $default-spacing;
  }
  .component-body {
    padding: 0 $double-spacing $double-spacing $double-spacing;
  }
}
