@use '~@angular/material' as mat;
@import "theme.scss";
.d10-top-bar {
  height: $top-bar-height !important;
  background: mat.get-color-from-palette($d10-primary, 900) !important;
}
.d10-page {
  display: flex;
  flex-direction: column;
  position: absolute;
  @include location($top-bar-height, 0, 0);
  background: mat.get-color-from-palette($d10-primary, 800) !important;
}
.d10-page.desktop-wide,
.d10-page.desktop {
  flex-direction: row;
}
.d10-menu {
  flex: 0 1 auto;
  position: relative;
  padding: $double-spacing $default-spacing;
  mat-accordion {
    .mat-expansion-panel {
      margin: 0 !important;
      max-height: 0;
      transition: max-height $default-transition;
      .mat-expansion-panel-header {
        height: ($default-size - $default-spacing) !important;
        margin: 0;
        padding: 0 22px;
        font-size: 16px;
        border: $half-spacing solid transparent;
        border-radius: $default-radius;
        color: mat.get-color-from-palette($d10-primary, 300);
      }
      .mat-expansion-panel-header.mat-expanded {
        color: mat.get-color-from-palette($d10-primary, 50);
      }
      .mat-expansion-panel-body {
        padding: 0;
        z-index: 4;
        ul {
          margin: 0;
          padding: $default-spacing $double-spacing;
          @include border-frame(800);
          background: mat.get-color-from-palette($d10-primary, 900);
          li {
            list-style: none;
            padding: $half-spacing $default-spacing;
            a {
              color: mat.get-color-from-palette($d10-primary, 300);
            }
            a.active,
            a:hover {
              color: mat.get-color-from-palette($d10-primary, 50);
              fa-icon {
                color: mat.get-color-from-palette($d10-accent);
              }
            }
          }
        }
      }
    }
    .mat-expansion-panel:not([class*='mat-elevation-z']) {
      box-shadow: none;
      background: mat.get-color-from-palette($d10-primary, 800);
      color: mat.get-color-from-palette($d10-primary, 50);
    }
    .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) {
      .mat-expansion-panel-header:hover {
        background: rgba(255,255,255,.04);
      }
    }
  }
  mat-accordion.expanded {
    .mat-expansion-panel {
      max-height: 15em;
    }
  }
  table.d10-menu-title {
    max-width: 174px;
    padding: 0 $default-spacing $default-spacing;
    font-weight: bold;
    color: mat.get-color-from-palette($d10-primary, 200);
    td {
      padding: 0 $half-spacing;
      fa-icon {
        font-size: 32px;
        color: mat.get-color-from-palette($d10-primary, 100);
      }
    }
  }
  table.d10-menu-title:hover {
    color: mat.get-color-from-palette($d10-primary, 100);
    cursor: pointer;
    td {
      fa-icon {
        color: mat.get-color-from-palette($d10-primary, 100);
      }
    }
  }
  button.mat-icon-button.d10-menu-button {
    position: absolute;
    @include location($double-spacing, $default-margin, auto, auto);
    @include size($default-size);
    color: mat.get-color-from-palette($d10-primary, 100);
    .mat-button-wrapper fa-icon {
      font-size: 21px;
    }
    fa-icon.closed {
      transition: $default-transition;
      opacity: 1;
      svg {
        transition: $default-transition;
        transform: rotate(0deg);
      }
    }
    fa-icon.open {
      transition: $default-transition;
      opacity: 0;
      svg {
        transition: $default-transition;
        transform: rotate(0deg);
      }
    }
  }
  button.mat-icon-button.d10-menu-button.expanded {
    fa-icon.closed {
      opacity: 0;
      svg {
        transform: rotate(90deg);
      }
    }
    fa-icon.open {
      opacity: 1;
      svg {
        transform: rotate(90deg);
      }
    }
  }
  button.mat-icon-button.d10-user-button {
    position: absolute;
    @include location($double-spacing, $default-margin + $default-size + $default-spacing, auto, auto);
    @include size($default-size);
    color: mat.get-color-from-palette($d10-primary, 100);
    .mat-button-wrapper fa-icon {
      font-size: 20px;
    }
    fa-icon {
      transition: $default-transition;
      svg {
        transition: $default-transition;
      }
    }
  }
  button.mat-icon-button.d10-user-button.expanded {
    fa-icon {
      svg {
        transform: rotate(360deg);
      }
    }
  }
  .bottom-menu {
    bottom: $default-spacing;
    position: absolute;
  }
}

.d10-content-wrapper {
  flex: 1 1 auto;
  position: relative;
  @include size(100%);
  @include border-top-radius($default-margin);
  overflow: hidden;
  z-index: 1;
  background: mat.get-color-from-palette($d10-primary, 700) !important;
  .d10-content-overlay {
    position: absolute;
    @include location(0, 0);
    opacity: 0;
    z-index: 9;
    background: mat.get-color-from-palette($d10-primary, 900);
  }
  .d10-content {
    position: absolute;
    @include location($default-margin, 0, 0);
    padding: 0 $default-margin $default-margin;
    overflow: auto;
    z-index: 2;
    .d10-content-width {
      max-width: 1024px;
      &.wide-screen {
        max-width: 1520px;
      }
    }
  }
}
.desktop-wide .d10-content-wrapper,
.desktop .d10-content-wrapper {
  border-top-right-radius: 0;
}
*::-webkit-scrollbar {
  @include size($default-spacing, $half-spacing);
}
*::-webkit-scrollbar-thumb {
  background: mat.get-color-from-palette($d10-primary, 800);
  border-radius: 4px;
}
*::-webkit-scrollbar-corner {
  background: transparent !important;
}
.mat-snack-bar-container {
  background: mat.get-color-from-palette($d10-primary, 900);
  color: mat.get-color-from-palette($d10-primary, 100);
  .mat-simple-snackbar-action button {
    color: mat.get-color-from-palette($d10-warn);
  }
}
.d10-user-menu {
  transition: max-height $default-transition;
}
