@use '~@angular/material' as mat;
@import '~@angular/material/theming';
@include mat.core();

$d10-brown: (
  50: #faebd7,
  100: #a19a91,
  200: #867d73,
  300: #6d675f,
  400: #504b45,
  500: #403C37,
  600: #37332f,
  700: #2f2d2a,
  800: #201f1d,
  900: #161513,
  A100: #C7BCA8,
  A200: #B7A98F,
  A400: #68543C,
  A700: #433523,
  A900: #292015,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $light-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
    A900: $light-primary-text,
  )
); // accent $mat-cyan
$d10-light-brown: (
  50: #D7CFC1,
  100: #C7BCA8,
  200: #B7A98F,
  300: #937E5D,
  400: #68543C,
  500: #5A4934,
  600: #4C3C28,
  700: #433523,
  800: #3A2D1D,
  900: #292015,
  A100: #a19a91,
  A200: #867d73,
  A400: #504b45,
  A700: #2f2d2a,
  A900: #161513,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $light-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
    A900: $light-primary-text,
  )
); // accent $mat-amber
$d10-dark-blue: (
  50: #D4D3D9,
  100: #9F9DAA,
  200: #7F7C8D,
  300: #6B6878,
  400: #575562,
  500: #414049,
  600: #3A3743,
  700: #302E38,
  800: #26252D,
  900: #1C1B22,
  A100: #AA9DA9,
  A200: #8D7C89,
  A400: #625561,
  A700: #382E34,
  A900: #221B22,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $light-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
    A900: $light-primary-text,
  )
); // accent $d10-neon-blue
$d10-neon-blue: (
  50: #EBFFFA,
  100: #C2FFF0,
  200: #99FFE6,
  300: #70FFDB,
  400: #47FFD1,
  500: #00FABD,
  600: #21C7A6,
  700: #178C81,
  800: #126D64,
  900: #0A343C,
  A100: #C2FFFA,
  A200: #85FFF5,
  A400: #00fae7,
  A700: #00625a,
  A900: #002f2b,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $light-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
    A900: $light-primary-text,
  )
);
$d10-steel: (
  50: #EAEAEB,
  100: #CACACC,
  200: #A7A7AA,
  300: #848488,
  400: #69696F,
  500: #4F4F55,
  600: #48484E,
  700: #3F3F44,
  800: #36363B,
  900: #26262A,
  A100: #ddd1e2,
  A200: #b1a8b8,
  A400: #6d6978,
  A700: #3e3e4b,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$d10-primary: mat.define-palette($d10-steel);
$d10-accent: mat.define-palette(mat.$cyan-palette);
$d10-warn: mat.define-palette(mat.$deep-orange-palette);
$d10-success: mat.define-palette(mat.$light-green-palette);

$d10-gold: mat.define-palette(mat.$amber-palette);
$d10-silver: mat.define-palette(mat.$blue-grey-palette, 400);
$d10-copper: mat.define-palette(mat.$brown-palette, 400);
$cc-active: mat.define-palette(mat.$amber-palette);
$cc-passive: mat.define-palette(mat.$light-blue-palette);

$colors: (
  "primary": ($d10-primary, 200, 700),
  "accent": ($d10-accent, 200, 700),
  "warn": ($d10-warn, 900, 200),
  "success": ($d10-success, 800, 200),
  "gold": ($d10-gold, 200, 700),
  "silver": ($d10-silver, 200, 700),
  "copper": ($d10-copper, 200, 700),
  "active": ($cc-active, 200, 700),
  "passive": ($cc-passive, 200, 700)
);

$d10-theme: mat.define-dark-theme((
  color: (
    primary: $d10-primary,
    accent: $d10-accent,
    warn: $d10-warn,
  )
));
@include mat.all-component-themes($d10-theme);

// Global values
$default-spacing: 8px;
$double-spacing: $default-spacing * 2;
$half-spacing: $default-spacing / 2;
$default-margin: 24px;
$default-radius: 8px;
$top-bar-height: 10px;
$default-transition: 500ms;
$default-size: 48px;
@mixin location($top: auto, $right: auto, $bottom: $top, $left: $right) {
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}
@mixin size($width: auto, $height: $width) {
  width: $width;
  height: $height;
}
@mixin border-top-radius($right, $left: $right) {
  border-top-right-radius: $right;
  border-top-left-radius: $left;
}
@mixin border-frame($hue: 500) {
  border: $half-spacing solid transparent;
  border-image: url("assets/border-frames/frame_square_steel_" + $hue + ".svg") 75 / 28px stretch;
  border-radius: 16px !important;
}
