@use '~@angular/material' as mat;
@import "theme.scss";

.character-info {
  text-align: center;
  color: mat.get-color-from-palette($d10-primary, 200) !important;
}
.character-wrapper {
  display: flex;
  .character-primary-container {
    max-width: 1024px;
    width: 100%;
  }
  .character-sidebar {
    flex: auto;
    padding: 43px 0 0 16px;
  }
}
.character-component {
  .mat-expansion-panel.component-body {
    margin: 0;
  }
  .component-body {
    @include border-frame(700);
    background: mat.get-color-from-palette($d10-primary, 500) !important;
    .component-header {
      padding: $default-spacing $double-spacing;
      @include border-frame(500);
      background: mat.get-color-from-palette($d10-primary, 700) !important;
      display: flex;
      flex: 1;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      overflow: hidden;
      font-weight: 500;
      .component-header-title {
        font-size: 15px;
      }
      .component-header-description {
        font-size: 12px;
        color: mat.get-color-from-palette($d10-primary, 500);
        text-align: right;
      }
    }
    .component-content {
      padding: 0 16px 8px;
      min-width: 64px;
    }
    .component-content.tight {
      padding: 0 8px 8px;
    }
    .component-title {
      padding: $default-spacing 0;
      font-weight: 500;
      @include border-frame(500);
      background: mat.get-color-from-palette($d10-primary, 700) !important;
    }
  }
  .component-body.simple {
    padding: $default-spacing $double-spacing;
  }
  .mat-checkbox {
    margin: 0 2px;
    .mat-checkbox-checkmark {
      display: none !important;
    }
    .mat-checkbox-background,
    .mat-checkbox-frame {
      border-radius: $half-spacing;
    }
    .mat-checkbox-frame {
      border-color: mat.get-color-from-palette($d10-primary, 700) !important;
    }
  }
  .mat-checkbox.small {
    margin: 0 1px;
    .mat-checkbox-inner-container {
      @include size(10px);
    }
  }
  .mat-checkbox.mat-checkbox-disabled {
    .mat-checkbox-frame {
      border-color: mat.get-color-from-palette($d10-primary, 600) !important;
    }
  }
  .mat-checkbox-disabled.mat-checkbox-checked,
  .mat-checkbox-disabled.mat-checkbox-indeterminate {
    .mat-checkbox-background {
      background-color: mat.get-color-from-palette($d10-primary, 600);
    }
  }
  .mat-tab-group {
    margin: 0 4px;
    .mat-tab-header {
      border-bottom: 1px solid mat.get-color-from-palette($d10-primary, 700);
      margin: 0 4px;
    }
    .mat-tab-label {
      height: $default-margin + $default-spacing !important;
      border-radius: $half-spacing;
      opacity: 1;
      color: mat.get-color-from-palette($d10-primary, 700);
      min-width: 72px;
    }
    .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled) {
      background-color: unset;
    }
    .mat-tab-label.mat-tab-label-active {
      color: mat.get-color-from-palette($d10-accent);
    }
    .mat-ink-bar {
      background-color: mat.get-color-from-palette($d10-accent);
    }
  }
  .character-ability {
    width: 100%;
    min-width: 64px;
    padding: $default-spacing;
    text-align: center;
    .title {
      padding: $default-spacing 0;
      @include border-frame(500);
      background: mat.get-color-from-palette($d10-primary, 700) !important;
    }
  }
  .wounds-indicator {
    .component-title {
      fa-icon {
        color: mat.get-color-from-palette($d10-warn);
      }
    }
    .mat-checkbox.mat-checkbox-checked {
      .mat-checkbox-background {
        transition: $default-transition;
        background-color: mat.get-color-from-palette($d10-primary, 700);
      }
    }
    .mat-checkbox.mat-checkbox-checked.minor {
      .mat-checkbox-background {
        clip-path: polygon(0 25%, 75% 100%, 0% 100%);
      }
    }
    .mat-checkbox.mat-checkbox-checked.serious {
      .mat-checkbox-background {
        clip-path: polygon(20% 0, 100% 80%, 100% 100%, 0 100%, 0 0);
      }
    }
    .mat-checkbox.critical {
      .mat-checkbox-mixedmark {
        opacity: 1 !important;
        transform: scaleX(1) rotate(45deg) !important;
        background-color: mat.get-color-from-palette($d10-primary, 700);
      }
    }
    .mat-checkbox.mat-checkbox-checked.critical {
      .mat-checkbox-frame {
        border-color: mat.get-color-from-palette($d10-warn) !important;
      }
      .mat-checkbox-background {
        background-color: mat.get-color-from-palette($d10-warn);
      }
      .mat-checkbox-mixedmark {
        opacity: 0 !important;
      }
    }
  }
  .character-panel {
    padding: $default-spacing;
    text-align: center;
    .component-title {
      fa-icon {
        color: mat.get-color-from-palette($d10-accent);
      }
    }
  }
  .skill-group {
    display: flex;
    flex-flow: wrap column;
    max-height: $default-margin * 3;
    fa-icon {
      flex: 0 0 100%;
      order: 1;
      align-self: flex-end;
      font-size: $default-size;
      color: mat.get-color-from-palette($d10-primary, 700);
    }
    .flex-component {
      flex: 0 0 75%;
    }
    .title {
      margin-left: 2px;
    }
  }
}
.swiper-container {
  padding-top: $double-spacing !important;
  .swiper-pagination-bullets {
    @include location(0, 1%!important, auto!important);
    width: 35% !important;
    margin: auto;
    background: mat.get-color-from-palette($d10-primary, 700);
  }
  .swiper-pagination-bullet {
    opacity: 1;
    border-radius: 25%;
    background: mat.get-color-from-palette($d10-primary, 500);
  }
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: mat.get-color-from-palette($d10-accent);
  }
  .slide-header {
    position: absolute;
    @include location(-$double-spacing + 2, $default-spacing, auto);
    border-color: mat.get-color-from-palette($d10-primary, 500);
  }
}
.checkbox-container {
  margin: 0 -4px;
}
.checkbox-container.small {
  .mat-checkbox {
    margin: 0 1px;
    .mat-checkbox-inner-container {
      @include size(10px);
    }
  }
}
.checkbox-container.light {
  .mat-checkbox {
    .mat-checkbox-frame {
      border-color: mat.get-color-from-palette($d10-primary, 400) !important;
    }
  }
  .mat-checkbox.mat-checkbox-disabled {
    .mat-checkbox-frame {
      border-color: mat.get-color-from-palette($d10-primary, 600) !important;
    }
  }
  .mat-checkbox-disabled.mat-checkbox-checked,
  .mat-checkbox-disabled.mat-checkbox-indeterminate {
    .mat-checkbox-background {
      background-color: mat.get-color-from-palette($d10-primary, 600);
    }
  }
}
